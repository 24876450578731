// Imports
@import "dropdown.scss";

// Colors
$dark: #101115;
$light: #ffffff;
$gray: #999999;
$gradient-button: linear-gradient(92.5deg, #4366e8 0%, #ff375b 100%);
$gradient-blue: linear-gradient(135deg, #4366e8 0%, #92a9ff 100%);
$gradient-yellow: linear-gradient(135deg, #eb991d 0%, #ffd28e 100%);
$gradient-light-blue: linear-gradient(135deg, #0089b5 0%, #9ee7fe 100%);
$gradient-red: linear-gradient(135deg, #ff375b 0%, #ff96a9 100%);
$gradient-green: linear-gradient(135deg, #08b94f 0%, #64ee9b 100%);
$gradient-purple: linear-gradient(135deg, #774def 0%, #b89fff 100%);

.gradient-blue {
  background: $gradient-blue;
}
.gradient-yellow {
  background: $gradient-yellow;
}
.gradient-light-blue {
  background: $gradient-light-blue;
}
.gradient-red {
  background: $gradient-red;
}
.gradient-green {
  background: $gradient-green;
}
.gradient-purple {
  background: $gradient-purple;
}

.img {
  max-width: 100%;
}

// Fonts
$poppins: "Poppins", sans-serif;
$playfair: 'Playfair Display', serif;
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: $dark;
}
h1 {
  color: $dark;
  font-family: $playfair;
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;

  @media (max-width: 575.98px){
    font-size: 48px;
    line-height: 58px;
  }
}

img {
  @media (max-width: 575.98px){
    max-width: 100%;
  }
}

a {
  text-decoration: none;
}

body {
  font-family: $poppins;
  font-size: 14px;
  line-height: 24px;
  color: $dark;
  background-color: $light;
  background-repeat: no-repeat;
  background-size: contain;

  &.dark-bg {
    background-color: $dark;
    background-size: contain;
    background-position: top center, 500px 50%;
    background-repeat: no-repeat;
    

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
      color: $light;
    }

    p {
      color: $gray;
    }

    .navbar-toggler-icon {
      i {
        color: $light;
      }
    }

    .nav-link {
      color: rgba($light, 0.5);
    }
    .nav-link {
      &.active, &:hover {
        color: $light;
      }
    }

    .dropdown {
      .nav-link {
        color: rgba($dark, 0.5);
      }
      .nav-link {
        &.active, &:hover {
          color: $dark;
        }
      }

      .dropdown-link {
        color: rgba($light, 0.5);

        &.active, &:hover {
          color: $light;
        }
      }
    }

    .copy {
      color: $light;
    }
  }
}

// Button
.btn {
  color: $light !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0;
  padding: 16px 32px;
  transition: all .3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  i {
    margin-left: 16px;
  }
}

.btn-group-sm>.btn, .btn-sm {
  padding: .35rem .8rem !important;
  font-size: .875rem !important;
  border-radius: .2rem !important;

  i {
    margin-left: 8px;
  }
}

.btn-gradient {
  color: $light;
  background: $gradient-button;
}

.navbar {
  @media (min-width:768px){
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .navbar-toggler {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    i {
      color: $dark;
      font-size: 36px;
    }
  }

  .navbar-nav {
    @media (max-width: 575.98px){
      padding-top: 24px;
      text-align: center;
    }
  }

  .nav-item {
    @media (min-width:768px){
      padding-right: 32px;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.nav-link {
  color: rgba($dark, 0.5);
  font-family: $poppins;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}
.nav-link {
  &.active, &:hover {
    color: $dark;
  }
}

.footer {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .address-info {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 64px;
  }
  .copy {
    color: $dark;
    margin-top: 64px;
  }
}

.hero-main {
  @media (min-width: 992px){
    margin-top: -80px;
  }
  @media (max-width: 991px){
    margin-bottom: 100px;
  }
  .col-left {
    @media (max-width: 991px){
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    h1 {
      padding-bottom: 32px;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      padding-bottom: 32px;
      margin: 0;
    }
  }

  .col-right {
    @media (max-width: 991px) {
      text-align: center;
    }
  }
}

.hero-inner {
  padding-top: 5rem;
  @media (min-width:992px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  clear: both;
}

.title-section {
  font-family: $playfair;
  font-size: 48px;
  font-weight: 700;
  color: $dark;
  margin-bottom: 64px;
}

.service-section {
  background-position: top right;
  background-repeat: no-repeat;

  @media (max-width: 991px){
    padding-bottom: 0;
  }

  .title-section {
    margin-bottom: 100px;
  }
}

.service-list {
  .row {
    @media (min-width: 992px){
      margin-bottom: 100px;
    }

    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .col-service {
      @media (max-width: 575.98px){
        margin-bottom: 40px;
      }

      @media (min-width: 768px){
        padding-right: 1rem;
        padding-left: 1rem;
      }

      @media (min-width: 1200px){
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }
  }
  .icons {
    width: 80px;
    height: 80px;
    margin: 0 auto 24px;
    border-radius: 20px;

    i {
      color: $light;
      font-size: 36px;
      position: relative;
      top: 20px;
    }
  }

  .title-col {
    color: $dark;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;

    a {
      color: $light;
    }
  }

  .info-col {
    @media (min-width:992px){
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.about-section {
  position: relative;

  .col-left {
    @media (max-width: 991px){
      padding-bottom: 50px;
    }

    p {
      margin-bottom: 32px;
    }
  }
  .col-right {
    text-align: center;
  }
}

.client-section {
  background-color: $light;

  .title-section {
    color: $dark;
    margin-bottom: 100px;
  }

  .slide-client {
    .item {
      height: 100%;
    }
    img {
//      height: 60px;
    }

    title {
      display: block;
      text-align: center;
    }

    .owl-stage {
      display: flex;
    }

    .owl-item {
      align-self: center;
    }
  }
}

.price-col {
  background-color: $light;
  padding: 1rem;
  border-radius: 6px;

  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }

  .list-group-item {
    border-color: rgba($gray, 0.1);
  }

  .btn {
    margin-top: 1rem;
  }
}

.demo-list {
  .demo-col {
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &:hover {
      .op-col {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .op-col {
    background-color: rgba($dark, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;

    .btn {
      margin-top: 30%;
    }
  }
}

.blog-section {
  .title-col {
    a {
      color: $dark;

      &:hover {
        color: rgba($dark, 0.7);
      }
    }
  }

  small {
    color: $gray;
  }
}
