.dropdown-toggle.active-dropdown:after {
  transform: rotate(-90deg);
}

.btco-hover-menu .active a,
.btco-hover-menu .active a:focus,
.btco-hover-menu .active a:hover,
.btco-hover-menu li a:hover,
.btco-hover-menu li a:focus,
.navbar > .show > a, .navbar > .show > a:focus, .navbar > .show > a:hover {
  color: #000;
  background: transparent;
  outline: 0;
}

/*submenu style start from here*/
.dropdown-menu {
  padding: 0px 0;
  margin: 0 0 0;
  border: 0px solid transition !important;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: small;
}

/*first level*/
.btco-hover-menu .collapse ul ul > li:hover > a, .navbar .show .dropdown-menu > li > a:focus, .navbar .show .dropdown-menu > li > a:hover {
  background: #fff;
}

/*second level*/
.btco-hover-menu .collapse ul ul ul > li:hover > a {
  background: #fff;
}

/*third level*/
.btco-hover-menu .collapse ul ul, .btco-hover-menu .collapse ul ul.dropdown-menu {
  background: #fff;
}

.btco-hover-menu .collapse ul ul ul, .btco-hover-menu .collapse ul ul ul.dropdown-menu {
  background: #fff;
}

.btco-hover-menu .collapse ul ul ul ul, .btco-hover-menu .collapse ul ul ul ul.dropdown-menu {
  background: #fff;
}

/*Drop-down menu work on hover*/
@media only screen and (max-width: 991px) {
  .btco-hover-menu .show > .dropdown-toggle::after {
    transform: rotate(-90deg);
  }
}
@media only screen and (min-width: 991px) {
  .btco-hover-menu .collapse ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul li:hover > ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    display: none;
  }

  /*******/
  .btco-hover-menu .collapse ul ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul ul li:hover > ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
    display: none;
  }

  /*******/
  .btco-hover-menu .collapse ul ul ul li {
    position: relative;
  }

  .btco-hover-menu .collapse ul ul ul li:hover ul {
    display: block;
  }

  .btco-hover-menu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 200px;
    display: none;
    z-index: 1;
  }
}
.gradient-blue {
  background: linear-gradient(135deg, #4366e8 0%, #92a9ff 100%);
}

.gradient-yellow {
  background: linear-gradient(135deg, #eb991d 0%, #ffd28e 100%);
}

.gradient-light-blue {
  background: linear-gradient(135deg, #0089b5 0%, #9ee7fe 100%);
}

.gradient-red {
  background: linear-gradient(135deg, #ff375b 0%, #ff96a9 100%);
}

.gradient-green {
  background: linear-gradient(135deg, #08b94f 0%, #64ee9b 100%);
}

.gradient-purple {
  background: linear-gradient(135deg, #774def 0%, #b89fff 100%);
}

.img {
  max-width: 100%;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #101115;
}

h1 {
  color: #101115;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 48px;
    line-height: 58px;
  }
}

@media (max-width: 575.98px) {
  img {
    max-width: 100%;
  }
}

a {
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #101115;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
}
body.dark-bg {
  background-color: #101115;
  background-size: contain;
  background-position: top center, 500px 50%;
  background-repeat: no-repeat;
}
body.dark-bg .h1, body.dark-bg .h2, body.dark-bg .h3, body.dark-bg .h4, body.dark-bg .h5, body.dark-bg .h6, body.dark-bg h1, body.dark-bg h2, body.dark-bg h3, body.dark-bg h4, body.dark-bg h5, body.dark-bg h6 {
  color: #ffffff;
}
body.dark-bg p {
  color: #999999;
}
body.dark-bg .navbar-toggler-icon i {
  color: #ffffff;
}
body.dark-bg .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-bg .nav-link.active, body.dark-bg .nav-link:hover {
  color: #ffffff;
}
body.dark-bg .dropdown .nav-link {
  color: rgba(16, 17, 21, 0.5);
}
body.dark-bg .dropdown .nav-link.active, body.dark-bg .dropdown .nav-link:hover {
  color: #101115;
}
body.dark-bg .dropdown .dropdown-link {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-bg .dropdown .dropdown-link.active, body.dark-bg .dropdown .dropdown-link:hover {
  color: #ffffff;
}
body.dark-bg .copy {
  color: #ffffff;
}

.btn {
  color: #ffffff !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0;
  padding: 16px 32px;
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  transform: translateY(-5px);
}
.btn i {
  margin-left: 16px;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.35rem 0.8rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.2rem !important;
}
.btn-group-sm > .btn i, .btn-sm i {
  margin-left: 8px;
}

.btn-gradient {
  color: #ffffff;
  background: linear-gradient(92.5deg, #4366e8 0%, #ff375b 100%);
}

@media (min-width: 768px) {
  .navbar {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.navbar .navbar-toggler {
  box-shadow: none;
}
.navbar .navbar-toggler-icon i {
  color: #101115;
  font-size: 36px;
}
@media (max-width: 575.98px) {
  .navbar .navbar-nav {
    padding-top: 24px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .navbar .nav-item {
    padding-right: 32px;
  }
}
.navbar .nav-item:last-child {
  padding-right: 0;
}

.nav-link {
  color: rgba(16, 17, 21, 0.5);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.nav-link.active, .nav-link:hover {
  color: #101115;
}

.footer {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.footer .address-info {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 64px;
}
.footer .copy {
  color: #101115;
  margin-top: 64px;
}

@media (min-width: 992px) {
  .hero-main {
    margin-top: -80px;
  }
}
@media (max-width: 991px) {
  .hero-main {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .hero-main .col-left {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.hero-main .col-left h1 {
  padding-bottom: 32px;
}
.hero-main .col-left p {
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 32px;
  margin: 0;
}
@media (max-width: 991px) {
  .hero-main .col-right {
    text-align: center;
  }
}

.hero-inner {
  padding-top: 5rem;
}
@media (min-width: 992px) {
  .hero-inner {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  clear: both;
}

.title-section {
  font-family: "Playfair Display", serif;
  font-size: 48px;
  font-weight: 700;
  color: #101115;
  margin-bottom: 64px;
}

.service-section {
  background-position: top right;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .service-section {
    padding-bottom: 0;
  }
}
.service-section .title-section {
  margin-bottom: 100px;
}

.service-list .row {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .service-list .row {
    margin-bottom: 100px;
  }
}
.service-list .row:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .service-list .row .col-service {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .service-list .row .col-service {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .service-list .row .col-service {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.service-list .icons {
  width: 80px;
  height: 80px;
  margin: 0 auto 24px;
  border-radius: 20px;
}
.service-list .icons i {
  color: #ffffff;
  font-size: 36px;
  position: relative;
  top: 20px;
}
.service-list .title-col {
  color: #101115;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}
.service-list .title-col a {
  color: #ffffff;
}
@media (min-width: 992px) {
  .service-list .info-col {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.about-section {
  position: relative;
}
@media (max-width: 991px) {
  .about-section .col-left {
    padding-bottom: 50px;
  }
}
.about-section .col-left p {
  margin-bottom: 32px;
}
.about-section .col-right {
  text-align: center;
}

.client-section {
  background-color: #ffffff;
}
.client-section .title-section {
  color: #101115;
  margin-bottom: 100px;
}
.client-section .slide-client .item {
  height: 100%;
}
.client-section .slide-client title {
  display: block;
  text-align: center;
}
.client-section .slide-client .owl-stage {
  display: flex;
}
.client-section .slide-client .owl-item {
  align-self: center;
}

.price-col {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 6px;
}
@media (max-width: 1024px) {
  .price-col {
    margin-bottom: 2rem;
  }
}
.price-col .list-group-item {
  border-color: rgba(153, 153, 153, 0.1);
}
.price-col .btn {
  margin-top: 1rem;
}

.demo-list .demo-col {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.demo-list .demo-col:hover .op-col {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.demo-list .op-col {
  background-color: rgba(16, 17, 21, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.demo-list .op-col .btn {
  margin-top: 30%;
}

.blog-section .title-col a {
  color: #101115;
}
.blog-section .title-col a:hover {
  color: rgba(16, 17, 21, 0.7);
}
.blog-section small {
  color: #999999;
}